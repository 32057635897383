
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";

export default defineComponent({
  name: "edit-company-modal",
  props: {
    data: {
      type: Object,
    },
  },
  setup(props) {
    var formData = ref({});

    const formRef = ref<null | HTMLFormElement>(null);
    const editGroupModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    interface InaturesData {
      group_nature_name;
      group_nature_id;
    }

    interface IAccountGroupI {
      group_name;
      group_id;
      parent_group;
    }

    var naturesData = ref<Array<InaturesData>>([]);
    const setNaturesData = async () => {
      const db_data = { page: 1, records_per_page: 10 };

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_group_natures/list",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            var naturesDataI = ref<Array<InaturesData>>([]);

            for (let j = 0; j < data.data.length; j++) {
              if (data.data[j]["group_nature_id"] == 0) {
                continue;
              }
              naturesDataI.value = Array({
                group_nature_name: data.data[j]["group_nature_name"],
                group_nature_id: data.data[j]["group_nature_id"],
              });

              naturesData.value.splice(
                j,
                naturesDataI.value.length,
                ...naturesDataI.value
              );
              console.log("nature");
              console.log(naturesData);
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    var acGroup = ref<Array<IAccountGroupI>>([]);
    const setAcGroup = async () => {
      const db_data = {};

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_groups/parent_list",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            var acGroupT = ref<Array<IAccountGroupI>>([]);
            var k = 0;
            for (let j = 0; j < data.data.length; j++) {
              acGroupT.value = Array({
                group_name: data.data[j]["group_name"],
                group_id: data.data[j]["group_id"],
                parent_group: 0,
              });

              acGroup.value.splice(k, acGroupT.value.length, ...acGroupT.value);

              if (data.data[j]["child_data"]) {
                for (let i = 0; i < data.data[j]["child_data"].length; i++) {
                  acGroupT.value = Array({
                    group_name: data.data[j]["child_data"][i]["group_name"],
                    group_id: data.data[j]["child_data"][i]["group_id"],
                    parent_group: data.data[j]["child_data"][i]["group_id"],
                  });
                  k = k + 1;
                  acGroup.value.splice(
                    k,
                    acGroupT.value.length,
                    ...acGroupT.value
                  );
                }
              }
              k = k + 1;
            }

            console.log("acGroup");
            console.log(acGroup);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setFormData = async (data) => {
      var j = 0;

      try {
        var values = { group_id: data.ids, page: 1, records_per_page: 10 };
        await axios
          .post("https://elogicalservices.mymetal.in/fa_groups/list", values, {
            headers: {
              "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
            },
          })
          .then(({ data }) => {
            formData.value = {
              parent_select: data.data[j]["parent_group"]["group_id"],
              nature_select: data.data[j]["group_nature"]["group_nature_id"],
              group_name: data.data[j]["group_name"],
              group_id: data.data[j]["group_id"],
              active: data.data[j]["active"] ? "Yes" : "No",
            };
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    onMounted(async () => {
      await setNaturesData();
      await setAcGroup();
      await setFormData(props.data);
    });

    const rules = ref({
      group_name: [
        {
          required: true,
          message: "Company Name is required",
          trigger: "change",
        },
      ],
      nature_select: [
        {
          required: true,
          message: "Nature of Business is required",
          trigger: "change",
        },
      ],
      parent_select: [
        {
          required: true,
          message: "Group Type is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      const db_data = {
        group_id: data.group_id,
        group_name: data.group_name,
        group_nature: data.nature_select,
        parent_group: data.parent_select,
        active: data.active == "Yes" ? true : false,
        created_user_id: "1",
        created_ip: "0.0.0.0",
        created_uagent: "Test",
      };

      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_groups/update",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            console.log(db_data);
            console.log(data);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }
      console.log(formData.value);
      await setCompanyData(formData.value);

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          setTimeout(() => {
            loading.value = false;

            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(editGroupModalRef.value);
            });
          }, 2000);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      editGroupModalRef,
      acGroup,
      naturesData,
    };
  },
});
