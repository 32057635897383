
import { defineComponent, onMounted, ref } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import axios from "axios";
import EditGroupModal from "@/components/modals/forms/EditGroupModal.vue";

// console.log(this.$route.query.test)

export default defineComponent({
  props: {
    id: { required: true },
  },
  name: "group-details",
  components: {
    EditGroupModal,
  },

  setup(props) {
    var ids = ref(props.id);
    console.log("props");
    console.log(props);

    interface ViewCompanies {
      parent_group_name;
      group_nature_name;
      group_name;
      group_id;
      active: {
        label: string;
        color: string;
      };
    }

    var resultsView = ref<ViewCompanies>();

    resultsView.value = {
      parent_group_name: "",
      group_nature_name: "",
      group_name: "",
      group_id: "",
      active: {
        label: "",
        color: "",
      },
    };

    const getUsers = async () => {
      try {
        var values = { group_id: props.id, page: 1, records_per_page: 10 };
        await axios
          .post("https://elogicalservices.mymetal.in/fa_groups/list", values, {
            headers: {
              "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
            },
          })
          .then(({ data }) => {
            var active_label = "";
            var active_color = "";
            var j = 0;

            if (data.data[j]["active"]) {
              active_label = "Yes";
              active_color = "success";
            } else {
              active_label = "No";
              active_color = "danger";
            }

            resultsView.value = {
              parent_group_name: data.data[j]["parent_group"]["group_name"],
              group_nature_name:
                data.data[j]["group_nature"]["group_nature_name"],
              group_name: data.data[j]["group_name"],
              group_id: data.data[j]["group_id"],
              active: {
                label: active_label,
                color: active_color,
              },
            };
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    onMounted(async () => {
      await getUsers();
      setCurrentPageBreadcrumbs("Groups Details", ["Groups"]);
    });

    return {
      resultsView,
      ids,
    };
  },
});
